<template>
  <div class="exam-card">
    <span class="card-title">答题卡</span>
    <div class="examing" v-if="$route.query.examid || $route.query.vid">
      <p class="examing-time-text" v-if="!$route.query.vid">
        <img src="../assets/images/clock.png" alt="" />
        <span>已用时</span>
      </p>
      <p class="examing-time">{{ timer }}</p>
      <div class="examing-tips">
        <span>题号</span>
        <b class="tips-done">已做</b>
        <b class="tips-not-done">未做</b>
      </div>
      <ul class="exmaing-question-number">
        <li v-for="(key, i) in userAnswer" :key="i">
          <span
            @click="toQuestion(i)"
            :style="{
              background: key.user_answer != '' ? '#F99359' : '#e6e6e6',
              color: key.user_answer != '' ? '#fff' : '',
            }"
            >{{ i + 1 }}</span
          >
        </li>
      </ul>
      <button @click="handlePaper">点击交卷</button>
    </div>
    <div class="examed" v-else>
      <p v-if="$route.query.pid">
        <span>本次考试成绩</span>
        <span
          ><b>{{ analysisCard.scores }}</b
          >分</span
        >
      </p>
      <ul class="examed-types" v-if="$route.query.pid">
        <li class="examed-type">
          <p class="type-title">题数</p>
          <p class="type-content">{{ analysisCard.question_total }}道</p>
        </li>
        <li class="examed-type">
          <p class="type-title">答对</p>
          <p class="type-content">{{ analysisCard.right_num }}道</p>
        </li>
        <li class="examed-type">
          <p class="type-title">用时</p>
          <p class="type-content">
            {{ Math.ceil(analysisCard.answer_time / 60) }}分钟
          </p>
        </li>
      </ul>
      <div class="examed-tips">
        <span class="tips-title">题号</span>
        <b class="tips-legend tips-legend-correct">正确</b>
        <b class="tips-legend tips-legend-error">错误</b>
        <b class="tips-legend tips-legend-no-answer">未作答</b>
      </div>
      <div class="examed-number">
        <div
          class="number-box"
          v-for="(result, index) in analysisResult"
          :key="result.id"
        >
          <i
            :class="{
              correct: result.status == 1,
              error: result.status == 2 && result.user_answer != '',
            }"
            @click="toCurrentAnswer(index)"
            >{{ index + 1 }}</i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExamCard",
  props: {
    userAnswer: { type: Array, default: () => [] },
    timer: { type: String },
    analysisCard: { type: Object, default: () => {} },
    analysisResult: { type: Array, default: () => [] },
  },
  data() {
    return {
      examed: true,
    };
  },

  components: {},

  computed: {},
  mounted() {},
  methods: {
    toQuestion(i) {
      this.$emit("toQuestion", i);
    },
    toCurrentAnswer(i) {
      this.$emit("toAnswer", i);
    },
    handlePaper() {
      this.$confirm("确认交卷吗", "提示", {
        confirButtonText: "确认",
        cancleButtonText: "取消",
      })
        .then(() => {
          console.log(1);
          this.$emit("handPaper");
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.exam-card {
  // max-width: 282px;
  width: 282px;
  height: 100%;
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 5px;
  p {
    margin: 0;
  }
  .card-title {
    display: block;
    height: 50px;
    background: #8bb9fc;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
  }
  .examing {
    .examing-time-text {
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-top: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 5px;
      }
    }
    .examing-time {
      color: #333333;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
    }
    .examing-tips {
      width: 100%;
      height: 46px;
      line-height: 46px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
      padding-left: 13px;
      margin-top: 22px;
      box-sizing: border-box;
      span {
        color: #333333;
        font-size: 18px;
      }
      b {
        color: #666666;
        font-size: 13px;
        position: relative;
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          display: block;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
        }
      }
      .tips-not-done {
        margin-left: 38px;
        &::before {
          background-color: #e6e6e6;
        }
      }
      .tips-done {
        margin-left: 45%;
        &::before {
          background-color: #f99359;
        }
      }
    }
    .exmaing-question-number {
      width: 100%;
      padding: 0 20px;
      margin-top: 20px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      li {
        display: inline-block;
        list-style-type: none;
        width: 20%;
        text-align: center;
        span {
          width: 28px;
          height: 28px;
          line-height: 28px;
          border-radius: 50%;
          display: block;
          margin: 15px auto 0;
          background: #e6e6e6;
          font-size: 12px;
          color: #666666;
          cursor: pointer;
        }
      }
      .question-number-checked {
        background-color: #f99359;
        color: #fff;
      }
    }
    button {
      display: block;
      width: 236px;
      height: 45px;
      background: #27c968;
      border-radius: 8px;
      border: 0;
      color: #ffffff;
      font-size: 18px;
      margin: 80px auto;
      cursor: pointer;
    }
  }
  .examed {
    & > p {
      font-size: 16px;
      color: #333333;
      text-align: center;
      margin-top: 17px;
      span {
        display: block;
        cursor: default;
        margin-bottom: 10px;
      }
      b {
        color: #ef1d1d;
        font-size: 39px;
      }
    }
    ul.examed-types {
      display: flex;
      width: 210px;
      height: 57px;
      justify-content: space-between;
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
      li.examed-type {
        list-style-type: none;
        .type-title {
          color: #999999;
        }
        .type-content {
          font-size: 14px;
          color: #333333;
          margin-top: 15px;
        }
      }
    }
    .examed-tips {
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      background: rgba($color: #fff, $alpha: 0.51);
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
      padding: 0 16px 0 13px;
      box-sizing: border-box;
      line-height: 46px;
      position: relative;
      .tips-title {
        color: #333333;
        font-size: 18px;
        margin-right: 50px;
      }
      .tips-legend {
        font-size: 12px;
        color: #666666;
        font-weight: normal;
        position: relative;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          border-radius: 50%;
          background-color: #000;
        }
      }
      .tips-legend-correct {
        &::before {
          background-color: #3ba9f6;
        }
      }
      .tips-legend-error {
        &::before {
          background-color: #ff6665;
        }
      }
      .tips-legend-no-answer {
        &::before {
          background-color: #e6e6e6;
        }
      }
    }
    .examed-number {
      width: 100%;
      padding: 18px;
      box-sizing: border-box;
      .number-box {
        width: 20%;
        display: inline-block;
        margin-top: 15px;
        i {
          display: block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          background: #e6e6e6;
          border-radius: 50%;
          margin: 0 auto;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          color: #666666;
          cursor: pointer;
        }
        i.correct {
          background-color: #3ba9f6;
          color: #ffffff;
        }
        i.error {
          background-color: #ff6665;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
