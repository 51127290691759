<template>
  <div class="result-page">
    <!-- TODO:面包屑导航 -->
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="$router.go(-1)">练习与考试</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{ cTitle }}解析</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="result-paper">
      <ul>
        <li
          class="result-item"
          v-for="(analysis, index) in analysisResult"
          :key="analysis.id"
          :ref="`item${index}`"
        >
          <p class="item-title">
            {{ index + 1 }}、
            <i
              >（{{
                analysis.topic_type == 1
                  ? "单选题"
                  : analysis.topic_type == 2
                  ? "多选题"
                  : "判断题"
              }}）</i
            >{{ analysis.question }}
          </p>
          <!-- 单选 -->
          <div v-if="analysis.topic_type === 1">
            <ol
              class="item-option"
              v-for="(value, key, answer_index) in JSON.parse(analysis.answer)"
              :key="answer_index"
            >
              <li>
                <img
                  src="../assets/images/yes.png"
                  alt=""
                  v-if="
                    (analysis.user_answer == key &&
                      analysis.real_answer == key) ||
                    analysis.real_answer == key
                  "
                />
                <img
                  src="../assets/images/no.png"
                  alt=""
                  v-if="
                    analysis.user_answer != analysis.real_answer &&
                    analysis.user_answer == key
                  "
                />
                <b
                  v-if="
                    analysis.user_answer != key && analysis.real_answer != key
                  "
                  >{{ key }}</b
                >
                <span>{{ value }}</span>
              </li>
            </ol>
            <p class="itm-answer">
              正确答案是<i class="answer-yes"> {{ analysis.real_answer }}</i
              >, 你的答案是<i class="answer-no"> {{ analysis.user_answer }}</i>
            </p>
          </div>
          <!-- 多选 -->
          <div v-if="analysis.topic_type === 2">
            <ol
              class="item-option"
              v-for="(value, key, answer_index) in JSON.parse(analysis.answer)"
              :key="answer_index"
            >
              <li>
                <img
                  src="../assets/images/yes.png"
                  alt=""
                  v-if="
                    (analysis.user_answer.indexOf(key) != -1 &&
                      analysis.real_answer.indexOf(key) != -1) ||
                    analysis.real_answer.indexOf(key) != -1
                  "
                />
                <img
                  src="../assets/images/no.png"
                  alt=""
                  v-if="
                    analysis.user_answer.indexOf(key) != -1 &&
                    analysis.real_answer.indexOf(key) == -1
                  "
                />
                <b
                  v-if="
                    analysis.user_answer.indexOf(key) == -1 &&
                    analysis.real_answer.indexOf(key) == -1
                  "
                  >{{ key }}</b
                >
                <span>{{ value }}</span>
              </li>
            </ol>
            <p class="itm-answer">
              正确答案是<i class="answer-yes"> {{ analysis.real_answer }}</i
              >, 你的答案是<i class="answer-no"> {{ analysis.user_answer }}</i>
            </p>
          </div>
          <!-- 判断 -->
          <div v-if="analysis.topic_type === 3">
            <ol
              class="item-option"
              v-for="(value, key, answer_index) in JSON.parse(analysis.answer)"
              :key="answer_index"
            >
              <li>
                <img
                  src="../assets/images/yes.png"
                  alt=""
                  v-if="
                    analysis.user_answer == value &&
                    analysis.real_answer == value
                  "
                />
                <img
                  src="../assets/images/no.png"
                  alt=""
                  v-if="
                    analysis.user_answer != analysis.real_answer &&
                    analysis.user_answer == value
                  "
                />
                <b
                  class="judge-item"
                  v-if="
                    (analysis.user_answer != value &&
                      analysis.real_answer != value) ||
                    (analysis.user_answer != analysis.real_answer &&
                      analysis.user_answer != value)
                  "
                ></b>
                <span>{{ value }}</span>
              </li>
            </ol>
            <p class="itm-answer">
              正确答案是<i class="answer-yes"> {{ analysis.real_answer }}</i
              >, 你的答案是<i class="answer-no"> {{ analysis.user_answer }}</i>
            </p>
          </div>
        </li>
      </ul>
      <ExamCard
        :analysisCard="analysisCard"
        :analysisResult="analysisResult"
        @toAnswer="toAnswer"
      />
    </div>
  </div>
</template>

<script>
import ExamCard from "../components/ExamCard.vue";
export default {
  data() {
    return {
      analysisCard: {},
      analysisResult: [],
      cTitle: "解析页",
    };
  },

  components: { ExamCard },

  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.pid) {
      this.getResult();
    } else {
      this.getPracticeResult();
    }
  },
  methods: {
    // 考试解析
    getResult() {
      this.cTitle = this.$route.query.etitle;
      let fd = new FormData();
      fd.append("u_paper_id", this.$route.query.pid);
      this.axios.post("/exam/afterclass_analysis", fd).then((res) => {
        console.log(res);
        this.analysisCard = res.data.data;
        this.analysisResult = res.data.res;
      });
    },
    // 练习解析
    getPracticeResult() {
      this.cTitle = this.$route.query.etitle;
      let answers = JSON.parse(sessionStorage.getItem("panswer"));
      console.log(answers);
      this.analysisResult = answers.question_list;
    },
    toAnswer(i) {
      this.$refs[`item${i}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  destroyed() {
    sessionStorage.removeItem("panswer");
  },
};
</script>
<style lang="scss" scoped>
.result-page {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-top: 1px;
  p {
    margin: 0;
  }
  .result-paper {
    max-width: 1200px;
    height: 100%;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    ul {
      width: 893px;
      height: 100%;
      padding: 0;
      margin: 0;
      margin-bottom: 55px;
      background-color: #fff;
      border-radius: 4px;
      li.result-item {
        list-style-type: none;
        width: 100%;
        padding: 24px 20px;
        box-sizing: border-box;
        border-bottom: 1px dashed #d1cfcf;
        font-size: 16px;
        color: #333333;
        .item-title {
          i {
            font-style: normal;
            color: #fa711b;
          }
        }

        ol {
          padding: 0;
          padding-left: 40px;
          margin-top: 22px;
          box-sizing: border-box;
          li {
            list-style-type: none;
            display: flex;
            align-items: center;
            &:not(:first-child) {
              margin-top: 20px;
            }
            b {
              display: inline-block;
              width: 23px;
              height: 23px;
              line-height: 23px;
              text-align: center;
              font-weight: normal;
              border: 1px solid #fb772b;
              border-radius: 50%;
              color: #fb772b;
            }
            .judge-item {
              display: inline-block;
              width: 21px;
              height: 21px;
              text-align: center;
              font-weight: normal;
              border: 1px solid #fb772b;
              border-radius: 5px;
              color: #fb772b;
            }
            span {
              margin-left: 15px;
            }
          }
        }
        .itm-answer {
          margin-left: 40px;
          margin-top: 25px;
          i {
            font-style: normal;
          }
          .answer-yes {
            color: #3ba9f6;
          }
          .answer-no {
            color: #ff6665;
          }
        }
      }
    }
  }
}
</style>
